import React, { Component } from 'react';
import { Button } from 'reactstrap';
import {ModalBody,Modal} from 'reactstrap';

class ConfirmDialog extends Component {

    constructor (props) {
        super(props);

        this.state = {
            confirmModalOpen: this.props.confirmModalOpen
        };

        this.toggleDialog = this.toggleDialog.bind(this);
        this.clickDialogButton = this.clickDialogButton.bind(this);
        this.cancelButton = this.cancelButton.bind(this);
        this.confirmButton = this.confirmButton.bind(this);
    }


    toggleDialog (event) {
        this.setState({
            confirmModalOpen: !this.state.confirmModalOpen
        });
    }

    clickDialogButton (action) {
        this.toggleDialog();
        if (action) {
            action();
        }
    }

    cancelButton () {
        if (this.props.cancelButtonText) {
            return (
                <Button color="secondary" size="sm" onClick={() => this.clickDialogButton()}>{this.props.cancelButtonText}</Button>
            )
        }
        else {
            return (<div></div>);
        }
    };

    confirmButton () {
        if (this.props.confirmButtonText) {
            return (
                <Button color="danger" size="sm"
                        onClick={() => this.clickDialogButton(this.props.confirmAction)}>{this.props.confirmButtonText}</Button>
            );
        }
        else {
            return (<div></div>);
        }
    }

    render () {
        return (
            <div>
                <Modal isOpen={this.state.confirmModalOpen} fade={false} contentClassName="confirmModal" centered>
                    <ModalBody>
                        <div className="confirmMessage">{this.props.message}</div>
                        <div className="confirmButtons">
                            {this.cancelButton()}{this.confirmButton()}
                        </div>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

export default ConfirmDialog;