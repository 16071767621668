import "@babel/polyfill";
import React from 'react';
import { render } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';

import App from './App';


import Amplify from 'aws-amplify';
import config from './aws-exports';
Amplify.configure(config);


render((
    <BrowserRouter>
        <App/>
    </BrowserRouter>
), document.getElementById('root'));


