import React, { Component } from 'react';
import {Authenticator, Greetings} from 'aws-amplify-react/dist/Auth';
import '../App.css';
import AdminTools from '../components/AdminTools'
import PWPTheme from '../themes/PWPTheme';
import {Auth, I18n} from 'aws-amplify';
import jwtDecode from "jwt-decode";
import queryString from 'query-string';


class Admin extends Component {

   constructor (props) {
        super(props);

        const verifyMessage = 'Email address has been verified.  Please sign in to continue.';

           //Handle scenario where we've been routed her for a verification code
        let queryParams = this.props.location.search;
        let preloadedAuthState = 'signIn';
        if (queryParams) {
            let params = queryString.parse(queryParams);
            if (params && params.email) {


                Auth.confirmSignUp(params.email, params.code)
                    .then(() => {
                        alert(verifyMessage);
                    })
                    .catch((err) => {
                        console.error(err);
                        if (err.message.indexOf('Current status is CONFIRMED')) {
                            alert(verifyMessage);
                        }
                        else {
                            alert('Error confirming.  Please manually enter your email and code.');
                            preloadedAuthState = 'confirmSignUp';
                        }
                    });
            }
        }

        this.state={
            isAdmin: false,
            authState: preloadedAuthState
        };

        this.handleAuthStateChange = this.handleAuthStateChange.bind(this);

        const labels = {
            'en': {
                'Username': 'Email',
                'Name':'Full Name',
                'Create a new account':'Create PoliticalWatchParty.com Account',
                'Sign in to your account':'Sign in to manage your watch parties',
                'Confirm Sign Up':'Please enter the verification code we sent to your email',
                'Confirmation Code':'Verification Code'
            }
        };
        I18n.setLanguage('en');
        I18n.putVocabularies(labels);
    }

    handleAuthStateChange(authStatus) {
        Auth.currentSession()
            .then(data => {
                let jwt = data.accessToken.jwtToken;
                let decodedToken = jwtDecode(jwt);
                let groups = decodedToken["cognito:groups"];
                for (let group of groups) {
                    if (group==='Admins') {
                        this.setState({
                            isAdmin: true
                        });
                    }
                }

            })
            .catch(err => console.log(err));

        console.log('AUTH STATUS: '+authStatus);
        this.setState({
            authState: authStatus
        });
    }

    render() {

        let signUpConfig = {
            signUpFields: [
                {
                    label: 'Password (Minimum 8 characters, uppercase, lowercase, and number required)',
                    key: 'password',
                    required: true,
                    placeholder: 'Password',
                    type: 'password',
                    displayOrder: 2,
                },
                {
                    label: 'Email',
                    key: 'username',
                    required: true,
                    placeholder: 'Email',
                    type: 'email',
                    displayOrder: 1
                },
                {
                    label: 'Name',
                    key: 'name',
                    placeholder: 'Name',
                    required: true,
                    displayOrder: 3
                }
            ],
            hideAllDefaults: true

        };

        return (
            <Authenticator
                onStateChange={this.handleAuthStateChange}
                theme={PWPTheme}
                hide={[Greetings]}
                signUpConfig={signUpConfig}
                authState={this.state.authState}
            >

                <AdminTools authState={this.state.authState} isAdmin={this.state.isAdmin}/>
            </Authenticator>
        );


    }
}

export default Admin;