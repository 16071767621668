import React, { Component } from 'react';
import '../App.css';
import {
    Navbar,
    Nav,
    NavbarBrand,
    NavLink,
    NavItem,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Col, Row
} from 'reactstrap';
import {BrowserView, MobileView} from 'react-device-detect';
import {FaCaretSquareDown} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import NavLinks from '../components/NavLinks';
import Helmet from "react-helmet";

class Home extends Component {


    constructor (props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);
        this.state = {
            dropdownOpen: false
        };

    }

    toggleDropdown (){
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>PoliticalWatchParty.com - About</title>
                    <meta name="description" content="PoliticalWatchParty.com is a non-partisan website that's been built from the ground up to
                                help provide an easy way for politically engaged people to find social events near them.
                                Campaigns and politicians come and go, but PWP will be here (hopefully) to provide a resource
                                that's available year to year."/>
                    <meta property="og:title" content="PoliticalWatchParty.com - About"/>
                </Helmet>
                <Navbar className="pwpHeader">
                    <NavbarBrand className="pwpHeaderBrand">PoliticalWatchParty.com</NavbarBrand>
                    <Nav navbar>
                        <BrowserView>
                            <NavItem>
                                <NavLink href="/admin" className="headerLink">Add a Party</NavLink>
                                <NavLink href="/" className="headerLink">Home</NavLink>
                            </NavItem>
                        </BrowserView>
                        <MobileView>
                            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle nav>
                                    <IconContext.Provider value={{ size: '1.5em'}}>
                                        <FaCaretSquareDown className="mobileMenu"/>
                                    </IconContext.Provider>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <NavLinks/>
                                </DropdownMenu>
                            </Dropdown>
                        </MobileView>
                    </Nav>
                </Navbar>
                <div class="about">
                    <Row>
                        <Col sm={0} md={3} lg={3}>
                        </Col>
                        <Col sm={12} md={6} lg={6}>
                            <h2>About PoliticalWatchParty.com</h2>
                            <p>
                                PoliticalWatchParty.com is a non-partisan website that's been built from the ground up to
                                help provide an easy way for politically engaged people to find social events near them.
                                Campaigns and politicians come and go, but PWP will be here (hopefully) to provide a resource
                                that's available year to year.
                            </p>
                            <p>
                                PWP is a labor of love from the folks behind <a href="http://www.livingliberally.org" target="_blank" rel="noopener noreferrer">Living Liberally</a>,
                                inspired by a
                                conversation at our national leader's conference in 2018.  Throughout the country, Living Liberally
                                chapters host events to watch debates, election night results, and big political speeches.  Other
                                organizations, aligned with candidates and not, also host events, so we wanted to create a one
                                stop shop for all of it.
                            </p>
                            <p>
                                Do you have ideas about how we can make this better?  Did you find a bug?  Please let us know
                                at <a href="https://support.politicalwatchparty.com">our support site</a>.
                            </p>
                        </Col>
                        <Col sm={0} md={3} lg={3}></Col>
                    </Row>

                </div>
            </div>
        );
    }
}

export default Home;