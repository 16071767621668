import React, { Component } from 'react';
import '../App.css';
import SingleEvent from '../components/SingleEvent.js'
import {Navbar, Nav, NavbarBrand, NavLink, NavItem, Dropdown, DropdownMenu, DropdownToggle} from 'reactstrap';
import {BrowserView, MobileView} from 'react-device-detect';
import {FaCaretSquareDown} from 'react-icons/fa';
import { IconContext } from 'react-icons';
import NavLinks from '../components/NavLinks';
import { Redirect } from 'react-router-dom';

class Home extends Component {


    constructor (props) {
        super(props);
        this.toggleDropdown = this.toggleDropdown.bind(this);

        this.state = {
            eventId: this.props.match.params.eventId,
            eventName: this.props.match.params.eventName,
            dropdownOpen: false
        };
    }

    toggleDropdown (){
        this.setState({
            dropdownOpen: !this.state.dropdownOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar className="pwpHeader">
                    <NavbarBrand className="pwpHeaderBrand">PoliticalWatchParty.com</NavbarBrand>
                    <Nav navbar>
                        <BrowserView>
                            <NavItem>
                                <NavLink href="/home" className="headerLink">Home</NavLink>
                                <NavLink href="/admin" className="headerLink">Add a Party</NavLink>
                                <NavLink href="/about" className="headerLink">About</NavLink>
                            </NavItem>
                        </BrowserView>
                        <MobileView>
                            <Dropdown nav isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown}>
                                <DropdownToggle nav>
                                    <IconContext.Provider value={{ size: '1.5em'}}>
                                        <FaCaretSquareDown className="mobileMenu"/>
                                    </IconContext.Provider>
                                </DropdownToggle>
                                <DropdownMenu right>
                                    <NavLinks/>
                                </DropdownMenu>
                            </Dropdown>
                        </MobileView>
                    </Nav>
                </Navbar>
                <SingleEvent eventId={this.state.eventId} eventName={this.state.eventName}/>
            </div>
        );
    }
}

export default Home;