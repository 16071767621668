import React, { Component } from 'react';
import '../App.css';
import {Auth} from 'aws-amplify';
import EventManagerPanel from '../components/EventManagerPanel';
import MasterEventPanel from '../components/MasterEventPanel';
import EventApprovalPanel from '../components/EventApprovalPanel';




import {Navbar, Nav, NavbarBrand, NavLink} from 'reactstrap';


class AdminTools extends Component {

    constructor (props) {
        super(props);

        this.state={
            showEventPanel: true,
            showMasterEventPanel: false,
            adminNavKey: 0,
            isAdmin: this.props.isAdmin,
            adminModalOpen: false,
            modal: {
                message: '',
                confirmText: '',
                cancelText: ''
            }
        };

        this.toggleNav = this.toggleNav.bind(this);
        this.modalBody = this.modalBody.bind(this);

    }


    async componentDidUpdate (prevProps, prevState) {
        if (!prevProps.isAdmin && this.props.isAdmin) {
            this.setState({
                adminNavKey: 1
            });
        }
    }

    async toggleNav (e) {
        switch(e) {
            case 'eventApproval':
                this.setState({
                    showEventPanel: false,
                    showMasterEventPanel: false,
                    showEventApprovalPanel: true
                });
                break;

            case 'masterEvents':
                this.setState({
                    showEventPanel: false,
                    showEventApprovalPanel: false,
                    showMasterEventPanel: true
                });
                break;
            case 'events':
                this.setState({
                    showMasterEventPanel: false,
                    showEventApprovalPanel: false,
                    showEventPanel: true,
                });
                break;
            default:
                this.setState({
                    showMasterEventPanel: false,
                    showEventApprovalPanel: false,
                    showEventPanel: false
                });
        }
    }

    adminNav () {
        console.log('IS ADMIN: '+this.props.isAdmin);
        if (this.props.isAdmin) {
            return (
                <Navbar>
                    <Nav>
                        <NavLink href="#" onClick={() => this.toggleNav('events')}>Events</NavLink>
                        <NavLink href="#" onClick={() => this.toggleNav('masterEvents')}>Master
                            Events</NavLink>
                        <NavLink href="#" onClick={() => this.toggleNav('eventApproval')}>Approve Events</NavLink>
                    </Nav>
                </Navbar>
            );
        }
    }

    modalBody () {

    }


    render() {
        if (this.props.authState==='signedIn') {
            return (
                <div>
                    <Navbar className="pwpHeader">
                        <NavbarBrand className="pwpHeaderBrand">PoliticalWatchParty.com</NavbarBrand>
                        <Nav>
                            <NavLink href="/" className="headerLink">Home</NavLink>
                            <NavLink href="#" className="headerLink" onClick={this.logout.bind(this)}>Log Out</NavLink>
                        </Nav>
                    </Navbar>
                    <div key={this.state.adminNavKey}>
                        {this.adminNav()}
                    </div>
                    <div>
                        <EventManagerPanel visible={this.state.showEventPanel}/>
                        <MasterEventPanel visible={this.state.showMasterEventPanel}/>
                        <EventApprovalPanel visible={this.state.showEventApprovalPanel}/>
                    </div>

                </div>
            );
        }
        else {
            return (<div></div>);
        }
    }

    logout(e) {
        Auth.signOut()
            .then(data => console.log(data))
            .catch(err => console.log(err)).then(window.location.reload());
    }

}


export default AdminTools;