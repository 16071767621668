import React, { Component } from 'react';
import { FaList, FaMap } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import {MobileView} from 'react-device-detect';
import {NavLink} from 'reactstrap';

class ListViewToggle extends Component {
    constructor (props) {
        super(props);

        this.state = {
            listView: false
        };

        this.toggleView = this.toggleView.bind(this);
        this.getIcon = this.getIcon.bind(this);
    }

    componentDidUpdate () {

    }

    toggleView () {
        this.setState({
            listView: !this.state.listView
        });

        this.props.mapToggle();
    }

    getIcon () {
        if (this.state.listView) {
            return <FaMap/>;
        }
        else {
            return <FaList/>;
        }
    }


    render () {
        return (
            <div className="listViewToggle">
                <MobileView>
                    <IconContext.Provider value={{ color: "1069ae", size: '1.5em'}}>
                        <div>
                            <NavLink onClick={this.toggleView}>
                                {this.getIcon()}
                            </NavLink>
                        </div>
                    </IconContext.Provider>

                </MobileView>
            </div>
        );
    }

}

export default ListViewToggle;