import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import { Button } from 'reactstrap';
import {Collapse } from 'reactstrap';
import {ModalBody,Modal} from 'reactstrap';
import {NavLink} from 'reactstrap';
import { FaTimesCircle } from 'react-icons/fa';
import { IconContext } from 'react-icons';
import { ButtonDropdown, DropdownToggle } from 'reactstrap';
import moment from 'moment';

class EventResult extends Component {

    constructor (props) {
        super(props);

        let contentClassName='modal-content-sm';
        if (window.innerWidth>500) {
            contentClassName='modal-content-lg';
        }

        let timeString=null;
        if (this.props.eventData.Time) {
            timeString = moment(this.props.eventData.Time).format('h:mm a');
        }
        this.state = {
            collapse: false,
            modalOpen: false,
            contentClassName: contentClassName,
            timeString: timeString
        };
        this.toggle = this.toggle.bind(this);
        this.renderCollapseButton = this.renderCollapseButton.bind(this);
        this.eventLink = this.eventLink.bind(this);
        this.eventDetails = this.eventDetails.bind(this);
        this.showModal = this.showModal.bind(this);


    }

    eventMarker (inMap) {

        if (inMap) {
            let markerClass="eventMarkerMap";
            if (this.props.displayNumberSize==='small') {
                markerClass=markerClass+'_small';
            }
            return (
                <span className={markerClass}>
                    <NavLink onClick={this.showModal}>{this.props.locationNumber}</NavLink>
               </span>
            );
        }
        else {
            return (
                <span className="eventMarkerList">
                   {this.props.locationNumber}
               </span>
            );
        }
    }

    showModal (event) {
        this.setState({
            modalOpen: !this.state.modalOpen
        });
    }

    renderCollapseButton () {
        if (this.props.eventData.Description) {
            return (
                <ButtonDropdown isOpen={this.state.dropdownOpen}  size="sm" toggle={this.toggle}>
                    <DropdownToggle caret color="primary">
                        Details
                    </DropdownToggle>
                </ButtonDropdown>
            )
        }
        else {
            return '';
        }
    }

    toggle (e) {
        this.setState({ collapse: !this.state.collapse });
    }

    getButtons () {
        let buttonList=[];
        if (this.props.editCallback) {
            buttonList.push(<Button color="primary" size="sm" className="adminButton" key="edit" onClick={() => this.props.editCallback(this.props.eventData)}>Edit</Button>);
        }
        if (this.props.deleteCallback) {
            buttonList.push(<Button color="primary" size="sm" className="adminButton" key="delete" onClick={() => this.props.deleteCallback(this.props.eventData)}>Delete</Button>);
        }
        return buttonList;
    }

    eventLink () {
        if (this.props.eventData.Link) {
            return (
                <Row>
                    <Col sm={12} md={12} lg={12}>
                        <a href={this.props.eventData.Link} target="_blank" rel="noopener noreferrer">Event Page</a>
                    </Col>
                </Row>
            );
        }
    }

    eventDetails (isModal) {
        let closeClassName='hideCloseButton';
        let cardClass='card';

        if (isModal) {
            closeClassName='closeButton';
            cardClass='card-noborder';
        }

        return (
            <div>
                <IconContext.Provider value={{ size: '1em'}}>
                    <NavLink onClick={this.showModal}><FaTimesCircle className={closeClassName}/></NavLink>
                </IconContext.Provider>
                <div className={cardClass}>
                    {this.getEventDetails(true)}
                    <Collapse isOpen={this.state.collapse}>
                        <div className="eventDescriptionContainer">
                            <ReactMarkdown source={this.props.eventData.Description} escapeHtml={true}/>
                        </div>
                    </Collapse>
                </div>
            </div>
        );
    }

    getEventDetails (showCollapseButton) {
        return (
            <div className="card-body">
                <div className="card-title">
                    <p>{this.eventMarker(false)}<span
                        className="eventName">{this.props.eventData.Name}</span>
                    </p>
                </div>
                {this.props.eventData.Time &&
                <div className="card-subtitle"><p>Start Time: {this.state.timeString}</p></div>
                }
                {this.props.distance &&
                <div className="card-subtitle"><p>Distance: {this.props.distance}</p></div>
                }
                {this.props.eventData.LocationName &&
                <div className="card-subtitle"><p>Venue: {this.props.eventData.LocationName}</p></div>
                }
                <div className="card-text">
                    <Row>
                        <Col sm={12} md={12} lg={12}>
                            {this.props.eventData.Address.Street}<br/>
                            {this.props.eventData.Address.City},
                            {this.props.eventData.Address.State}<br/>
                            {this.props.eventData.Address.ZipCode}<br/>
                        </Col>
                    </Row>
                    {this.eventLink()}
                    {showCollapseButton &&
                        <Row>
                            <Col sm={0} md={5} lg={5}/>
                            <Col sm={12} md={4} lg={4}>
                                <div className="collapseButton">
                                    {this.renderCollapseButton()}
                                </div>
                            </Col>
                            <Col sm={0} md={4} lg={4}/>
                        </Row>
                    }
                </div>
            </div>
        );
    }

    getSingleEvent () {
        return (
            <div>
                <div className="card">
                    {this.getEventDetails(false)}

                    <div className="eventDescriptionContainer">
                        <ReactMarkdown source={this.props.eventData.Description} escapeHtml={true}/>
                    </div>
                </div>
            </div>
        );
    }

    getAdminEntry () {
        return (
            <div>
                <Row>
                    <Col>
                        {this.props.eventData.Name}
                    </Col>
                    <Col>
                        {this.getButtons()}
                    </Col>

                </Row>
            </div>
        );
    }


    render () {
        switch (this.props.displayType) {
            case "numberOnly":
                return (
                    <div>
                        <Modal isOpen={this.state.modalOpen} fade={false} contentClassName={this.state.contentClassName} centered>
                            <ModalBody>
                                <div>
                                    {this.eventDetails(true)}
                                </div>
                            </ModalBody>
                        </Modal>
                        {this.eventMarker(true)}
                    </div>
                );
            case "admin":
                //Don't show if it's edit mode, the draft will come through
                if (this.props.eventData.editMode==='true') {
                    return(<div></div>);
                }
                return this.getAdminEntry();
            case "admin_approval":
                return this.getAdminEntry();
            case "single_event":
                return this.getSingleEvent();
            default:
                return (
                    <div>
                        <Row>
                            <Col sm={11} md={11} lg={11}>
                                {this.eventDetails()}
                            </Col>
                        </Row>
                    </div>
                );
        }
    }
}

export default EventResult;