import React, {Component} from 'react';
import EditEvent from "./EditEvent";
import EventList from "./EventList";
import {Col, Row} from "reactstrap";
import {API} from 'aws-amplify';


class EventApprovalPanel extends Component {

    constructor (props) {
        super(props);

        this.onClickEdit=this.onClickEdit.bind(this);
        this.refreshList = this.refreshList.bind(this);

        this.state = {
            eventList : [],
            action: 'Create',
            visible: this.props.visible
        };
    }

    async componentDidMount () {
        await this.refreshList();

    }

    async componentDidUpdate () {
        if (this.props.visible!==this.state.visible) {
            await this.refreshList();
            this.setState({visible: this.props.visible});
        }
    }

    async onClickEdit(e) {
        this.setState({
            recordToEdit: e
        });

    }

    async refreshList () {
        let apiPath = '/event/approvalQueue';

        let events = await API.get("EventAPI", apiPath);

        if (events) {
            this.setState({
                eventList: events,
                recordToEdit: null
            });
        }
    }

    render () {
        if (this.state.visible) {
            return (
                <div>
                    <Row>
                        <Col sm={0} md={2} lg={2}/>
                        <Col sm={12} md={4} lg={4}>
                            <h3>Approve Event</h3>
                            <EditEvent approvalMode={true} recordToEdit={this.state.recordToEdit} saveCallback={this.refreshList}/>
                        </Col>
                        <Col sm = {12} md = {6} lg = {6} >
                            <h3>Events Awaiting Approval</h3>
                            <EventList eventList={this.state.eventList}
                                       currentLocation={this.state.currentLocation}
                                       version={this.state.listVersion}
                                       editCallback={this.onClickEdit}
                                       displayType="admin_approval"/>
                        </Col>
                    </Row>
                </div>
            );
        }
        else {
            return (<div></div>);
        }

    }
}

export default EventApprovalPanel;