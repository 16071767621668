import React, {Component} from 'react';
import {Col, Row} from "reactstrap";
import PWPGeoUtil from '../util/PWPGeoUtil';
import {Label, Input, Button, FormGroup, Form} from "reactstrap";
import Editor from "rich-markdown-editor";
import moment from "moment";
import uuid from 'uuid/v4';
import {API} from 'aws-amplify';
import { DateTimePicker } from 'react-widgets';
import momentLocalizer from 'react-widgets-moment';
import omitEmpty from 'omit-empty';

import 'react-widgets/dist/css/react-widgets.css'
import MasterEventList from "./MasterEventList";
import {IconContext} from "react-icons";
import {FaPlus} from "react-icons/fa";

moment.locale('en');
momentLocalizer();

let geoutil = new PWPGeoUtil();

class MasterEventPanel extends Component {

    constructor (props) {
        super(props);

        this.onClickEdit=this.onClickEdit.bind(this);
        this.onClickDelete=this.onClickDelete.bind(this);
        this.saveMasterEvent=this.saveMasterEvent.bind(this);
        this.onChange=this.onChange.bind(this);
        this.onChangeDescription=this.onChangeDescription.bind(this);
        this.addEvent=this.addEvent.bind(this);

        this.state = {
            eventList : [],
            action: 'Create',
            visible: this.props.visible,
            MasterEventId: uuid.v4(),
            EventName: '',
            Description: '',
            DescriptionFunction: null,
            descriptionKey: 0,
            EventTime: new Date(),
            datePickerKey: 0,
            eventListKey: 0
        };
    }

    async addEvent (e) {
        this.setState({
            MasterEventId: uuid.v4(),
            EventName: '',
            Description: '',
            DescriptionFunction: null,
            descriptionKey: 0,
            EventTime: new Date()
        });
    }

    async componentDidMount () {
        let events = await geoutil.getEventsForUser();
        if (events) {
            this.setState({
                eventList: events,
                recordToEdit: null
            });
        }

    }

    componentDidUpdate () {
        if (this.props.visible!==this.state.visible) {
            this.setState({visible: this.props.visible});
        }
    }

    async onClickEdit(e) {
        this.setState({
            MasterEventId: e.Id,
            EventName: e.Name,
            EventTime: new Date(e.EventTime),
            datePickerKey: this.state.datePickerKey+1,
            Description: e.Description,
            descriptionKey: this.state.descriptionKey+1
        });

    }

    async onClickDelete(e) {
        console.log('DELETE CLICKED: '+JSON.stringify(e));
    }

    onChangeDate (e) {

        this.setState({
            EventTime: e,
            datePickerKey: this.state.datePickerKey+1
        });
    }

    onChangeDescription (e) {
        this.setState({DescriptionFunction: e});
    }

    onChange (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    async saveMasterEvent (e) {
        e.preventDefault();

        const apiPath = '/masterEvent';

        let descriptionValue = this.state.Description;
        if (this.state.DescriptionFunction) {
            descriptionValue = this.state.DescriptionFunction();
        }

        // Use the API module to save the note to the database
        try {
            let dateString = moment().toISOString();
            let eventTimeString = moment(this.state.EventTime).toISOString();
            let apiCall = {
                body: {
                    Id: this.state.MasterEventId,
                    Name: this.state.EventName,
                    DateAdded: dateString,
                    EventTime: eventTimeString,
                    Description: descriptionValue
                }
            };

            apiCall = omitEmpty(apiCall);

            const apiResponse = await API.put("MasterEvents", apiPath, apiCall);
            this.setState({apiResponse});
            this.setState({eventListKey: this.state.eventListKey+1});
            alert("SAVED!");
        } catch (e) {
            console.log(e);
        }
    }

    render () {
        if (this.state.visible) {
            return (
                <div>
                    <Form onSubmit={this.saveMasterEvent}>
                        <Row>
                            <Col sm={0} md={2} lg={2}/>
                            <Col sm={12} md={4} lg={4}>
                                <h3>{this.state.action} Master Event</h3>
                                <FormGroup>
                                    <Label>Master Event Name</Label>
                                    <Input type="text" name="EventName" onChange={this.onChange}
                                           value={this.state.EventName}/>
                                    <Label>Date and Time</Label>
                                    <DateTimePicker defaultValue={this.state.EventTime}
                                                    onChange={value => this.onChangeDate(value)}
                                                    value={this.state.EventTime}
                                                    key={this.state.datePickerKey}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <div className="descriptionBox">
                                        <Editor
                                            defaultValue={this.state.Description}
                                            onChange = {this.onChangeDescription}
                                            placeholder = "Describe your event"
                                            key={this.state.descriptionKey}/>
                                    </div>
                                    <Button color="primary">Save</Button>

                                </FormGroup>
                            </Col>
                            <Col sm={12} md={6} lg={6}>
                                <h3>Master Events
                                    <span onClick={this.addEvent}>
                                        <IconContext.Provider value={{ size: '1.0em'}}>
                                            <FaPlus className="addButton"/>
                                        </IconContext.Provider>
                                    </span>

                                </h3>
                                <MasterEventList key={this.state.eventListKey} editCallback={this.onClickEdit}/>
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        }
        else {
            return (<div></div>);
        }
    }
}

export default MasterEventPanel;