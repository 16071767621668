import React, { Component } from 'react';
import GoogleMapReact from 'google-map-react';
import {Row, Col, NavLink} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {Form, Input, Label} from 'reactstrap';
import {Button} from 'reactstrap';
import PWPGeoUtil from '../util/PWPGeoUtil';
import EventResult from './EventResult';
import EventList from './EventList';
import {API} from 'aws-amplify';
import moment from 'moment-timezone';
import {Modal, ModalBody} from 'reactstrap';
import ListViewToggle from './ListViewToggle';
import Cookies from 'universal-cookie';
import WelcomeMessage from './WelcomeMessage';
import isBot from 'isbot';
import MasterEventChooser from './MasterEventChooser';
import {isBrowser} from "react-device-detect";
import {IconContext} from "react-icons";
import { FaCrosshairs } from 'react-icons/fa';

const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);

const cookies = new Cookies();

class FindEvents extends Component {


    constructor (props) {

        super(props);

        let returnVisitor = false;
        let modalClass = 'welcomeMesageModal';

        if (cookies.get("returnVisitor")) {
            returnVisitor = true;
            modalClass = 'loadingModal';
        }


        let closedWelcomeDialog = returnVisitor;

        this.state = {
            center: {
                lat: 41.964370,
                lng: -87.673880
            },
            ZipCode: '',
            mapKey: 1,
            eventList: [],
            zoom: 13,
            bounds: {},
            currentLocation: {},
            MasterEvent: {},
            MasterEvents: [],
            listVersion: 0,
            mapClass: 'mapContainer',
            returnVisitor: returnVisitor,
            modalClass: modalClass,
            closedWelcomeDialog: closedWelcomeDialog,
            modalWindowOpen: true,
            defaultIndex: 0
        };

        this.googleMapsKey = process.env.REACT_APP_GOOGLE_MAPS_KEY;

        this.geoutil = new PWPGeoUtil();
        this.onZipCodeChange = this.onZipCodeChange.bind(this);
        this.blockEnter = this.blockEnter.bind(this);
        this.mapMoved = this.mapMoved.bind(this);
        this.getMasterEvents = this.getMasterEvents.bind(this);
        this.submitZipCode = this.submitZipCode.bind(this);
        this.mapLoaded = this.mapLoaded.bind(this);
        this.mapToggle = this.mapToggle.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.setMasterEvent = this.setMasterEvent.bind(this);
        this.findNearMe = this.findNearMe.bind(this);
        this.showFindMeButton = this.showFindMeButton.bind(this);
    }

    mapToggle () {
        if (this.state.mapClass==='mapContainer') {
            this.setState({
                mapClass: 'mapContainerClosed'
            });
        }
        else {
            this.setState({
                mapClass: 'mapContainer'
            });
        }
    }


    closeModal () {
        this.setState({
            modalWindowOpen: false,
            closedWelcomeDialog: true,
            returnVisitor: true
        });
        cookies.set("returnVisitor", true, {path: "/"});
    }


    async componentDidMount () {

        if (isBot(navigator.userAgent)) {
            await this.mountForBots();
        }
        else {
            await this.mountForHumans();
        }

    }

    //Bots just get the basic information, not maps, etc
    async mountForBots () {
        await this.getMasterEvents();
        let eventList = await this.geoutil.loadResultsForSingleHash(this.state.MasterEvent.Id);
        this.setState({
            eventList: eventList
        });
    }

    async mountForHumans () {

        await this.getMasterEvents();

        if (this.state.closedWelcomeDialog) {
            this.closeModal();
        }

        await this.updateMap();
    }

    async getMasterEvents () {
        let apiPath='/masterEvent';
        const apiResponse = await API.get("MasterEvents", apiPath);
        winston.debug('MASTER EVENT RESPONSE: ' + JSON.stringify(apiResponse));

        if (!apiResponse.error && apiResponse.length>0) {
            let today = moment();
            winston.debug('TODAY: '+today);
            let defaultIndex=0;
            for (let masterEvent of apiResponse) {
                let eventTime = moment(masterEvent.EventTime);
                winston.debug('Event Time: '+eventTime);
                if (today.isBefore(eventTime)) {
                    this.setState({
                        MasterEvents: apiResponse,
                        MasterEvent: masterEvent,
                        defaultIndex: defaultIndex
                    });
                    break;

                }
                defaultIndex++;
            }

        }
    }


    async setMasterEvent (masterEvent) {
        this.setState({
            MasterEvent: masterEvent
        });
        this.openModal();
        await this.updateMap(null,true);
        if (this.state.closedWelcomeDialog) {
            this.closeModal();
        }

    }

    async updateMap (coordinates, moveMap) {

        //If master event hasn't loaded yet, don't do anything here
        if (!this.state.MasterEvent.Id) {
            return;
        }

        if (!coordinates) {
            coordinates = this.state.center;
        }

        let eventList = await this.geoutil.getClosestEvents(this.state.MasterEvent.Id,coordinates,this.state.zoom, this.state.bounds);

        if (!eventList) {
            eventList=[];
        }

        winston.debug('***** TOTAL EVENTS: '+eventList.size);
        if (moveMap) {
            this.setState({
                center: {
                    lat: coordinates.lat,
                    lng: coordinates.lng
                },
                mapKey: this.state.mapKey + 1,
                eventList: eventList,
                listVersion: this.state.listVersion + 1
            });
        }
        else {
            this.setState({
                eventList: eventList,
                listVersion: this.state.listVersion + 1
            });
        }
    }

    /**
     * We need to capture the map load event and figure out the lat/lng to handle scenarios
     * where the zip code changes.  We can detect the map moving through the normal onChange
     * method, but it doesn't cover this scenario.
     *
     * @param e
     * @return {Promise<void>}
     */
    async mapLoaded (e) {

        let googleBounds = e.map.getBounds();
        let sw = googleBounds.getSouthWest();
        let ne = googleBounds.getNorthEast();

        let bounds = {
            "sw": {
                "lat":sw.lat(),
                "lng":sw.lng()
            },
            "ne": {
                "lat":ne.lat(),
                "lng":ne.lng()
            },
            "nw": {
                "lat": ne.lat(),
                "lng": sw.lng()
            },
            "se": {
                "lat": sw.lat(),
                "lng": ne.lng()
            }
        };

        winston.debug('+++++++ BOUNDS ON LOAD: '+JSON.stringify(bounds));
        this.setState({
            bounds: bounds
        });

        await this.updateMap();
    }

    async onZipCodeChange (e) {
        e.preventDefault();
        this.setState({[e.target.name]: e.target.value});

    }

    async blockEnter (e) {
        if (e.keyCode === 13) {
            e.preventDefault();
            if (e.target.value.length>4) {
                let zipCode = e.target.value;

                let coordinates = this.geoutil.getLatLongFromZip(zipCode);

                if (coordinates) {
                    this.setState(
                        {
                            center: {
                                lat: coordinates.lat,
                                lng: coordinates.lng
                            },
                            mapKey: this.state.mapKey + 1
                        }
                    );
                }
            }
        }
    }

    openModal () {
        this.setState({
            modalWindowOpen: true
        });
    }

    async submitZipCode (e) {
        e.preventDefault();
        this.openModal();

        let zipCode = this.state.ZipCode;
        let coordinates = this.geoutil.getLatLongFromZip(zipCode);

        if (coordinates) {
            this.setState(
                {
                    center: {
                        lat: coordinates.lat,
                        lng: coordinates.lng
                    },
                    mapKey: this.state.mapKey + 1
                }
            );
        }
        this.closeModal()
    }

    async findNearMe (e) {
        const geolocation = await navigator.geolocation;

        winston.debug('GEOLOCATION: '+geolocation);
        if (geolocation) {
            this.openModal();
            geolocation.getCurrentPosition(async (position) => {
                    let coordinates = {
                        lat: position.coords.latitude,
                        lng: position.coords.longitude
                    };
                    this.setState({currentLocation: coordinates});
                    await this.updateMap(coordinates, true);
                    if (this.state.returnVisitor) {
                        this.closeModal();
                    }
                },
                async (error) => {
                    winston.error('ERROR GETTING LOCATION: ' + error);
                    this.closeModal();
                    await this.updateMap();
                });
        }
    }

    async mapMoved (e) {
        if (e.center.lat!==this.state.center.lat ||
            e.center.lng!==this.state.center.lng ||
            e.zoom!==this.state.zoom) {
            this.setState({zoom: e.zoom});
            this.setState({bounds: e.bounds});
            await this.updateMap(e.center, false);
        }
    }

    showFindMeButton () {
        if (isBrowser) {
            return (
                <Button onClick={this.findNearMe} color="primary"
                             className="searchButton">Find Near Me</Button>
            );
        }
        else {
            return (
                <IconContext.Provider value={{ color: "1069ae", size: '1.5em'}} className="crosshairIcon">
                    <div className="crosshairDiv" onClick={this.findNearMe}>
                        <FaCrosshairs/>
                    </div>
                </IconContext.Provider>
            );
        }
    }


    render () {
        if (isBot(navigator.userAgent)) {
            return (
                <div>
                    <div>
                        <h1>{this.state.MasterEvent.Name}</h1>
                        <h2>{this.geoutil.getMasterEventDateString(this.state.MasterEvent.EventTime)}</h2>
                        <ReactMarkdown source={this.state.MasterEvent.Description} escapeHtml={true}/>
                    </div>
                    <div>
                        <h3>Event List</h3>
                    {this.state.eventList.map((e, i) => {
                        let encodedName = encodeURIComponent(e.Name);
                        let eventLink = '/event/'+encodedName+'/'+e.Id;
                        return (
                            <div key={i}><a href={eventLink}>{e.Name}</a><br/></div>
                        )
                    })}
                    </div>
                </div>
            )
        }
        else {
            return (
                <div className="findEventsPanel">
                    <Modal isOpen={this.state.modalWindowOpen} ref="loadingModal" fade={false}
                           contentClassName={this.state.modalClass} centered>
                        <ModalBody>
                            <WelcomeMessage closeAction={this.closeModal}
                                            returnVisitor={this.state.returnVisitor}/>
                        </ModalBody>
                    </Modal>
                    <Form>
                        <Row>
                            <Col sm={0} md={4} lg={4}>
                            </Col>
                            <Col sm={0} md={4} lg={4}>
                                <div className="searchBox">
                                    <Label>Zip Code</Label>
                                    <Input type="text" name="ZipCode" onChange={this.onZipCodeChange}
                                           onKeyDown={this.blockEnter} size="5"
                                           value={this.state.ZipCode}
                                    />
                                    <Button onClick={this.submitZipCode} color="primary"
                                            className="searchButton">Search</Button>
                                    {this.showFindMeButton()}
                                    <ListViewToggle mapToggle={this.mapToggle}/>
                                </div>
                            </Col>
                            <Col sm={0} md={4} lg={4}/>
                        </Row>
                        <Row>
                            <Col sm={12} md={8} lg={8}>
                                <div className={this.state.mapClass}>
                                    <GoogleMapReact bootstrapURLKeys={{key: this.googleMapsKey}}
                                                    center={this.state.center}
                                                    key={this.state.mapKey}
                                                    onChange={this.mapMoved}
                                                    zoom={this.state.zoom}
                                                    onGoogleApiLoaded={this.mapLoaded}
                                                    yesIWantToUseGoogleMapApiInternals={true}>
                                        {this.state.eventList.map((e, i) => {
                                            return (
                                                <EventResult
                                                    lat={e.Coordinates.lat}
                                                    lng={e.Coordinates.lng}
                                                    key={i}
                                                    locationNumber={i + 1}
                                                    eventData={e}
                                                    distance={e.distanceString}
                                                    displayType="numberOnly"
                                                />
                                            )
                                        })}
                                    </GoogleMapReact>
                                </div>
                            </Col>
                            <Col sm={12} md={4} lg={4}>
                                <Row>
                                    <MasterEventChooser masterEvents={this.state.MasterEvents}
                                                        setMasterEvent={this.setMasterEvent}
                                                        defaultIndex={this.state.defaultIndex}/>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <h3>{this.state.MasterEvent.Name}</h3>
                                        <h4 className="eventDateTime">{this.geoutil.getMasterEventDateString(this.state.MasterEvent.EventTime)}</h4>


                                        <ReactMarkdown source={this.state.MasterEvent.Description} escapeHtml={true}/>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>
                                        <EventList eventList={this.state.eventList}
                                                   currentLocation={this.state.currentLocation}
                                                   version={this.state.listVersion}/>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        }
    }
}

export default FindEvents;