import React, { Component } from 'react';
import geolib from 'geolib';
import EventResult from "./EventResult";

class EventList extends Component {
    constructor (props) {
        super(props);

        this.state = {
            version: 0
        };
    }

    componentDidUpdate () {

        let latLongCurrent = {};

        if (this.props.version!==this.state.version) {
            if (this.props.currentLocation && this.props.currentLocation.lat) {
                latLongCurrent = {
                    latitude: this.props.currentLocation.lat,
                    longitude: this.props.currentLocation.lng
                }
            }
            for (let index in this.props.eventList) {
                let e = this.props.eventList[index];
                if (this.props.currentLocation && this.props.currentLocation.lat) {
                    let latLongEvent = {
                        latitude: e.Coordinates.lat,
                        longitude: e.Coordinates.lng
                    }

                    let distance = geolib.getDistance(latLongCurrent, latLongEvent);
                    let distanceString = Math.round((distance / 1609.34) * 10) / 10 + ' Miles'; //convert to miles
                    e.distanceString = distanceString;
                    e.distance = distance;
                    //distanceStringList[index] = distanceString;
                }
                else {
                    e.distance = 0;
                    e.distanceString = 'Unknown';
                }
                this.props.eventList[index]=e; //Update the value with the distance string included
            }
            this.setState({
                version: this.props.version
            });

            this.props.eventList.sort(this.eventComparator);

        }
    }

    //Sort events by distance
    eventComparator (a, b) {

        if (a.distance < b.distance)
            return -1;
        if (a.distance > b.distance)
            return 1;
        return 0;

    }

    render () {
        return (
            <div>
                <div className="eventListContainer">
                {
                    this.props.eventList.map((e, i) =>{
                    return(
                        <EventResult
                            key={i}
                            locationNumber={i+1}
                            eventData={e}
                            displayType={this.props.displayType}
                            distance={e.distanceString}
                            editCallback={this.props.editCallback}
                            deleteCallback={this.props.deleteCallback}
                        />
                    )
                })}
                </div>
            </div>
        );
    }

}

export default EventList;