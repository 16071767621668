import React, { Component } from 'react';
import {Button, NavLink} from "reactstrap";
import {FaTimesCircle} from "react-icons/fa";
import {IconContext} from 'react-icons';
import ReactLoading from 'react-loading';

class WelcomeMessage extends Component {

    constructor (props) {
        super (props);
        this.state = {
            clickedClose: false
        };

        this.closeWindow = this.closeWindow.bind(this);
    }

    closeWindow (e) {
        this.setState({
            clickedClose: true
        });

        this.props.closeAction();
    }

    render () {
        if (this.props.returnVisitor || this.state.clickedClose) {
            return (<div>
                <ReactLoading type="spin" color="#1069ae" height="100%" width="100%" />
            </div>);
        }
        else {
            return (
                <div className="welcomeMessageContainer">
                    <div className="modalClose">
                        <IconContext.Provider value={{size: '1em'}}>
                            <NavLink href="#" onClick={() => this.props.closeAction()}><FaTimesCircle/></NavLink>
                        </IconContext.Provider>
                    </div>
                    <div className="welcomeMessage">
                        <h4>Welcome to PoliticalWatchParty.com!</h4>
                        <p>This site is here to help you find places where you can watch political events such as
                            State of the Union addresses, debates, and election nights. You can search by zip code
                            or interact with the map to find an event near you.
                        </p>
                        <p>To learn more, view our <a href="/about">About</a> page. Enjoy!</p>
                        <p className="welcomeMessageCloser">
                            <NavLink href="#" onClick={this.closeWindow}><Button
                                color="primary">Close</Button></NavLink>
                        </p>
                    </div>
                </div>
            );
        }
    }

}

export default WelcomeMessage;