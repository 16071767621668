import React, { Component } from 'react';
import MasterEventResult from "./MasterEventResult";
import {API} from 'aws-amplify';

class MasterEventList extends Component {
    constructor (props) {
        super(props);

        this.state = {
            version: 0,
            masterEventList: []
        };


    }

    async componentDidMount () {
        let apiPath = '/masterEvent';


        const apiResponse = await API.get("MasterEvents", apiPath);
        console.log('MASTER EVENT RESPONSE: ' + JSON.stringify(apiResponse));
        if (!apiResponse.error) {
            this.setState({
                masterEventList: apiResponse
            });
        }


    }

    componentDidUpdate () {


    }


    render () {
        switch (this.props.displayType) {
            case ("selectList"):
                return (
                    <div>
                        {
                            this.state.masterEventList.map((e, i) => {
                                return (
                                    <MasterEventResult
                                        masterEvent={e}
                                        displayType="selectList"
                                    />
                                );
                            })
                        }
                    </div>
                );
            default:
                return (
                    <div>
                        <div className="eventListContainer">
                            {
                                this.state.masterEventList.map((e, i) => {
                                    return (
                                        <MasterEventResult
                                            masterEvent={e}
                                            editCallback={this.props.editCallback}
                                            key={i}
                                        />
                                    )
                                })}
                        </div>
                    </div>
                );
        }
    }

}

export default MasterEventList;