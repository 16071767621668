import React, {Component} from 'react';
import EditEvent from "./EditEvent";
import EventList from "./EventList";
import {Col, Row} from "reactstrap";
import PWPGeoUtil from '../util/PWPGeoUtil';
import ConfirmDialog from "./ConfirmDialog";
import {API} from "aws-amplify";
import {FaPlus} from 'react-icons/fa';
import { IconContext } from 'react-icons';

const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);


let geoutil = new PWPGeoUtil();

class EventManagerPanel extends Component {

    constructor (props) {
        super(props);

        this.onClickEdit=this.onClickEdit.bind(this);
        this.onClickDelete=this.onClickDelete.bind(this);
        this.refreshList = this.refreshList.bind(this);
        this.confirmDelete = this.confirmDelete.bind(this);
        this.confirmSave = this.confirmSave.bind(this);
        this.addEvent = this.addEvent.bind(this);

        this.state = {
            eventList : [],
            action: 'Create',
            visible: this.props.visible,
            confirmOpen: false,
            confirmAction: null,
            confirmButtonText: '',
            cancelButtonText: '',
            confirmMessage: '',
            confirmKey: 0,
            editKey: 0,
        };
    }

    addEvent (e) {
        winston.debug('CLICKED ADD EVENT: '+e);
        this.setState({
            recordToEdit: null,
            editKey: this.editKey++,
            action: 'Create'
        });
    }

    async componentDidMount () {
        await this.refreshList();

    }

    async componentDidUpdate () {
        if (this.props.visible!==this.state.visible) {
            await this.refreshList();
            this.setState({visible: this.props.visible});
        }
    }

    onClickEdit(e) {
        winston.debug('EDIT CLICKED: '+JSON.stringify(e));
        this.setState({
            recordToEdit: e,
            action: 'Edit'
        });

    }

    onClickDelete(e) {
        winston.debug('DELETE CLICKED: '+JSON.stringify(e));
        this.setState({
            confirmOpen: true,
            confirmAction: this.confirmDelete,
            confirmButtonText: 'Delete Event',
            cancelButtonText: 'CANCEL! Do Not Delete!',
            confirmMessage: 'Are you sure you want to delete this event?',
            eventToDelete: e,
            recordToEdit: null
        });
        this.refs.confirmDialog.toggleDialog();
    }

    async confirmDelete () {
        winston.debug('EVENT TO DELETE: '+JSON.stringify(this.state.eventToDelete));
        let apiPath='/event/object/'+this.state.eventToDelete.Id;

        try {
            await API.del("EventAPI", apiPath, {});
            winston.debug('EVENT DELETED.  REFRESH');
            await this.refreshList();

            winston.debug('REFRESH DONE');
        }
        catch (err) {
            winston.error('ERROR: '+err);
        }

    }

    async refreshList () {
        winston.debug('Refreshing list');
        let events = await geoutil.getEventsForUser();
        if (events) {
            this.setState({
                eventList: events,
                recordToEdit: null
            });
        }
    }

    async confirmSave (message) {
        if (!message) {
            message = 'Your event has been saved.  It will not appear in the list until it has been approved.';
        }
        this.setState({
            confirmOpen: true,
            cancelButtonText: 'OK!',
            confirmButtonText: '',
            confirmMessage: message,
            recordToEdit: null
        });
        this.refs.confirmDialog.toggleDialog();
        await this.refreshList();
    }

    render () {
        if (this.state.visible) {
            return (
                <div>
                    <Row>
                        <Col sm={0} md={2} lg={2}/>
                        <Col sm={12} md={4} lg={4}>
                            <h3>{this.state.action} Event</h3>
                            <EditEvent recordToEdit={this.state.recordToEdit} saveCallback={this.confirmSave} key={this.state.editKey}/>
                        </Col>
                        <Col sm = {12} md = {6} lg = {6} >
                            <h3>Your Events

                                <span onClick={this.addEvent}>
                                    <IconContext.Provider value={{ size: '1.0em'}}>
                                        <FaPlus className="addButton"/>
                                    </IconContext.Provider>
                                </span>
                            </h3>
                            <EventList eventList={this.state.eventList}
                                       currentLocation={this.state.currentLocation}
                                       version={this.state.listVersion}
                                       editCallback={this.onClickEdit}
                                       deleteCallback={this.onClickDelete}
                                       displayType="admin"/>

                        </Col>
                    </Row>
                    <ConfirmDialog ref="confirmDialog"
                                   confirmAction={this.state.confirmAction}
                                   confirmButtonText={this.state.confirmButtonText}
                                   cancelButtonText={this.state.cancelButtonText}
                                   message={this.state.confirmMessage}/>

                </div>
            );
        }
        else {
            return (<div></div>);
        }

    }
}

export default EventManagerPanel;