import React, {Component} from 'react';
import GoogleMapReact from 'google-map-react';
import {Row, Col} from 'reactstrap';
import ReactMarkdown from 'react-markdown';
import {Form} from 'reactstrap';
import PWPGeoUtil from '../util/PWPGeoUtil';
import EventResult from './EventResult';
import isBot from 'isbot';
import Helmet from 'react-helmet';
import {Redirect} from 'react-router-dom'


const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);


class MasterEventDisplay extends Component {


    constructor(props) {

        super(props);

        this.state = {
            center: {},
            mapKey: 1,
            eventList: [],
            zoom: 13,
            bounds: {},
            MasterEvent: null,
            listVersion: 0,
            mapClass: 'mapContainer',
            defaultIndex: 0,
            notFound: false
        };


        this.geoutil = new PWPGeoUtil();

    }

    async componentDidMount() {
        let geoutil = new PWPGeoUtil();
        let masterEvent = await geoutil.loadSingleMasterEvent(this.props.masterEventId);
        if (!masterEvent || !masterEvent.Id) {
            this.setState({
                notFound: true
            });
        } else {
            let events = await geoutil.loadResultsForSingleHash(this.props.masterEventId);

            this.setState({
                eventList: events,
                MasterEvent: masterEvent
            });
        }
    }

    displayEventDetails() {
        if (this.state.eventList[0]) {
            let eventResult;
            for (let event of this.state.eventList) {
                let description = '';
                //description = event.Description.replace(/\n/g, ' ');
                //description = description.replace(/\\/g, '');

                let encodedName = encodeURIComponent(event.Name);
                let eventLink = '/event/'+encodedName+'/'+event.Id;
                eventResult = (
                    <div><a href={eventLink}>{event.Name}</a><br/>
                        <script type="application/ld+json">{`
                            {
                                "@context": "https://schema.org",
                                "@type": "Event",
                                "name": "${this.state.eventList[0].Name}",
                                "startDate": "${this.state.eventList[0].Time}",
                                "location": {
                                    "@type": "Place",
                                    "name": "${this.state.eventList[0].LocationName}",
                                    "address": {
                                        "@type": "PostalAddress",
                                        "streetAddress": "${this.state.eventList[0].Address.Street}",
                                        "addressLocality": "${this.state.eventList[0].Address.City}",
                                        "postalCode": "${this.state.eventList[0].Address.ZipCode}",
                                        "addressRegion": "${this.state.eventList[0].Address.State}",
                                        "addressCountry": "US"
                                    }
                                },
                                "description": "${description}"
                            }
                        `}</script>
                    </div>
                );
            }
            return (<div>{eventResult}</div>)
        } else {
            return <div></div>
        }
    }


    render() {
        if (this.state.notFound) {
            return (
                <div>
                    <Redirect to="/404"/>
                </div>
            );
        }
        if (this.state.MasterEvent) {
            let description = '';
            if (this.state.MasterEvent.Description) {
                description = this.state.MasterEvent.Description.replace(/\n/g, ' ');
                description = description.replace(/\\/g, '');
            }

            let baseUrl = window.location.protocol+'//'+window.location.host;
            let canonicalUrl = baseUrl+'/masterEvent/'+this.state.MasterEvent.Id;

            return (
                <div className="findEventsPanel">
                    {
                        <div>
                            <Helmet>
                                <title>{this.state.MasterEvent.Name} - PoliticalWatchParty.com</title>
                                <meta name="description" content={description}/>
                                <meta property="og:title"
                                      content={this.state.MasterEvent.Name + ' - PoliticalWatchParty.com'}/>
                                <link rel="canonical" href={canonicalUrl}/>
                            </Helmet>
                        </div>
                    }
                    <Form>
                        <Row>
                            <Col sm={12} md={12} lg={12}>
                                <Row>
                                    <Col sm={12} md={12} lg={12}>

                                        <h3 className="masterEventName">{this.state.MasterEvent.Name}</h3>
                                        <h4 className="eventDateTime">{this.geoutil.getMasterEventDateString(this.state.MasterEvent.EventTime)}</h4>


                                        <ReactMarkdown source={this.state.MasterEvent.Description} escapeHtml={true}/>

                                    </Col>
                                </Row>
                                {this.displayEventDetails()}
                            </Col>
                        </Row>
                    </Form>
                </div>
            );
        } else {
            return (<div></div>);
        }
    }
}

export default MasterEventDisplay;