import React, { Component } from 'react';
import {Row, Col, Form, FormGroup, Button, Label, Input} from 'reactstrap';
import {API} from 'aws-amplify';
import uuid from 'uuid/v4';
import PWPGeoUtil from '../util/PWPGeoUtil';
import geohasher from 'latlon-geohash';
import moment from 'moment';
import Editor from "rich-markdown-editor";
import omitEmpty from 'omit-empty';
import {DateTimePicker} from "react-widgets";

const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);


class EditEvent extends Component {


    constructor (props) {
        super(props);


        this.state = {
            apiResponse: null,
            EventId: uuid.v4(),
            EventName: '',
            EventLink: '',
            Street: '',
            City: '',
            State: '',
            ZipCode: '',
            LocationName: '',
            MasterEventId: '',
            Time: new Date(),
            Description: '',
            DescriptionFunction: null,
            descriptionKey: 0,
            MasterEvents: [],
            timePickerKey: 0,
            masterEventTimeMap: {}
        };




        this.saveEvent = this.saveEvent.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChangeDescription = this.onChangeDescription.bind(this);
        this.onChangeTime = this.onChangeTime.bind(this);
        this.onChangeMasterEvent = this.onChangeMasterEvent.bind(this);
        this.geoutil = new PWPGeoUtil();
    }


    componentDidUpdate (prevProps) {
        if (this.props.recordToEdit) {
            if (!this.state.EventId || this.state.EventId!==this.props.recordToEdit.Id) {

                let timeValue = this.state.masterEventTimeMap[this.props.recordToEdit.MasterEventId];
                if (this.props.recordToEdit.Time) {
                    timeValue = new Date(this.props.recordToEdit.Time);
                }

                this.setState({
                    EventId: this.props.recordToEdit.Id,
                    EventName: this.props.recordToEdit.Name,
                    EventLink: this.props.recordToEdit.Link,
                    Street: this.props.recordToEdit.Address.Street,
                    City: this.props.recordToEdit.Address.City,
                    State: this.props.recordToEdit.Address.State,
                    ZipCode: this.props.recordToEdit.Address.ZipCode,
                    LocationName: this.props.recordToEdit.LocationName,
                    Description: this.props.recordToEdit.Description,
                    Time: timeValue,
                    descriptionKey: this.state.descriptionKey+1,
                    MasterEventId: this.props.recordToEdit.MasterEventId
                });
            }
        }
        else if (prevProps.recordToEdit && !this.props.recordToEdit) {
            //Clear the values for a new record
            let masterEventId = Object.keys(this.state.masterEventTimeMap)[0];
            let timeValue = this.state.masterEventTimeMap[masterEventId];
            if (!timeValue) {
                timeValue = new Date();
            }

            this.setState({
                EventId: uuid.v4(),
                EventName: '',
                EventLink: '',
                Street: '',
                City: '',
                State: '',
                ZipCode: '',
                LocationName: '',
                MasterEventId: '',
                Description: '',
                Time: timeValue,
                DescriptionFunction: null,
                descriptionKey: 0
            });
        }
    }

    onChange (e) {
        this.setState({[e.target.name]: e.target.value});
    }

    onChangeDescription (e) {
        this.setState({DescriptionFunction: e});
    }

    async saveEvent (e) {
        e.preventDefault();

        let address =  {
            Street: this.state.Street,
            City: this.state.City,
            State: this.state.State,
            ZipCode: this.state.ZipCode
        };

        let coordinates;
        try {
            coordinates = await this.geoutil.getLatLong(this.state.Street + ' ' + this.state.City + ',' + this.state.State + ' ' + this.state.ZipCode);
            winston.debug('Coordinates: ' + JSON.stringify(coordinates));
            if (!coordinates) {
                this.props.saveCallback('We were unable to locate that address.  Please confirm the address and retry.');
                winston.error('FAILED TO GEOCODE');
                return;
            }
        }
        catch (err) {
            winston.error('Geocoding error: '+err);
            this.props.saveCallback('There was an error locating that address (network problem?).  Please try again.');
            return;
        }

        let geohash = geohasher.encode(coordinates.lat, coordinates.lng, 8);

        let apiPath = '/event';

        // Use the API module to save the note to the database
        try {
            let dateString = moment().toISOString();

            let descriptionValue = this.state.Description;
            if (this.state.DescriptionFunction) {
                descriptionValue = this.state.DescriptionFunction();
            }


            let timeValue = this.state.masterEventTimeMap[this.state.MasterEventId];
            if (this.state.Time) {
                timeValue = new Date(this.state.Time);
            }


            let apiCall = {
                body: {
                    Id: this.state.EventId,
                    Name: this.state.EventName,
                    Link: this.state.EventLink,
                    Address: address,
                    LocationName: this.state.LocationName,
                    Coordinates: coordinates,
                    Geohash: geohash,
                    MasterEventId: this.state.MasterEventId,
                    DateAdded: dateString,
                    Time: timeValue,
                    Description: descriptionValue
                }
            };

            apiCall = omitEmpty(apiCall);
            
            if (this.props.approvalMode) {
                apiPath=apiPath+'/approve';
            }
            winston.debug('EVENT: '+JSON.stringify(apiCall));
            const apiResponse = await API.put("EventAPI", apiPath, apiCall);
            this.setState({apiResponse});
            this.props.saveCallback();



        } catch (e) {
            winston.error(e);
        }
    }

    onChangeMasterEvent (e) {

        let dateTime = this.state.masterEventTimeMap[e.target.value];

        this.setState({
            MasterEventId: e.target.value,
            Time: dateTime
        });

    }

    onChangeTime (e) {
        this.setState({
            Time: e,
            timePickerKey: this.state.timePickerKey+1
        });
    }


    async componentDidMount () {
        let apiPath='/masterEvent';
        const apiResponse = await API.get("MasterEvents", apiPath);
        winston.debug('MASTER EVENT RESPONSE: ' + JSON.stringify(apiResponse));
        if (!apiResponse.error && apiResponse.length>0) {
            let masterEventOptions=[];
            let masterEventTimeMap = {};
            for (let index in apiResponse) {
                let masterEvent = apiResponse[index];
                masterEventOptions.push(<option key={index} value={masterEvent.Id}>{masterEvent.Name}</option>);
                masterEventTimeMap[masterEvent.Id]=new Date(masterEvent.EventTime);
            }
            this.setState({
                MasterEvents: masterEventOptions,
                MasterEventId: apiResponse[0].Id,
                masterEventTimeMap: masterEventTimeMap,
                Time: new Date(apiResponse[0].EventTime)
            });
        }
    }

    render () {
        return (
          <div>
              <Form onSubmit={this.saveEvent} >
                  <Row>
                      <Col sm="12" md="12" lg="12">
                          <FormGroup>
                              <Label>Master Event</Label>
                              <Input type="select" name="MasterEventId" onChange={this.onChangeMasterEvent}
                                     value={this.state.MasterEventId}>
                                  {this.state.MasterEvents}
                              </Input>
                            <Label>Event Name *</Label>
                              <Input type="text" name="EventName" onChange={this.onChange}
                                     value={this.state.EventName} required/>
                              <Label>Location Name</Label>
                              <Input type="text" name="LocationName" onChange={this.onChange}
                                     value={this.state.LocationName}/>
                              <Label>Event URL</Label>
                              <Input type="text" name="EventLink" onChange={this.onChange}
                                     value={this.state.EventLink}/>
                              <Label>Street *</Label>
                              <Input type="text" name="Street" onChange={this.onChange}
                                     value={this.state.Street} required/>
                              <Label>City *</Label>
                              <Input type="text" name="City" onChange={this.onChange}
                                     value={this.state.City} required/>
                              <Label>State *</Label>
                              <Input type="text" name="State" onChange={this.onChange}
                                     value={this.state.State} required/>
                              <Label>Zip Code</Label>
                              <Input type="text" name="ZipCode" onChange={this.onChange}
                                     value={this.state.ZipCode}/>
                              <Label>Start Time</Label>
                              <DateTimePicker defaultValue={this.state.Time}
                                  onChange={value => this.onChangeTime(value)}
                                  value={this.state.Time}
                                  time={true} date={false}
                                  key={this.state.timePickerKey}
                              />

                          </FormGroup>
                          <FormGroup>
                              <Label>Description</Label>
                              <div className="descriptionBox">
                                  <Editor
                                      defaultValue={this.state.Description}
                                      onChange = {this.onChangeDescription}
                                      placeholder = "Describe your event"
                                      key={this.state.descriptionKey}/>
                              </div>
                              <Button color="primary">Save</Button>

                          </FormGroup>
                      </Col>
                  </Row>
                  <Row>
                      <Col sm="12" md="12" lg="12">

                      </Col>
                  </Row>
              </Form>

          </div>
        );
    }
}

export default EditEvent;