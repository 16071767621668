import React, { Component } from 'react';
import {Row, Col} from 'reactstrap';
import { Button } from 'reactstrap';


class MasterEventResult extends Component {

    constructor (props) {
        super(props);

        this.state = {collapse: false};

    }


    render () {
        switch (this.props.displayType) {
            case "selectList":
                return (
                   <option value={this.props.masterEvent.Id}>{this.props.masterEvent.Name}</option>
                );
            default:
                return (
                    <Row>
                        <Col>
                            {this.props.masterEvent.Name}
                            {this.props.masterEvent.Date}
                        </Col>
                        <Col>
                            <Button onClick={() => this.props.editCallback(this.props.masterEvent)}>Edit</Button>
                        </Col>
                    </Row>
                );
        }
    }
}

export default MasterEventResult;