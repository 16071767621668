import React, { Component } from 'react';
import {DropdownItem, NavLink} from "reactstrap";


class NavLinks extends Component {
    render () {
        return (
            <DropdownItem>
                <NavLink href="/admin" className="dropdownMenuLink">Add a Party</NavLink>
                <NavLink href="/" className="dropdownMenuLink">Home</NavLink>
                <NavLink href="/about" className="dropdownMenuLink">About</NavLink>
            </DropdownItem>
        );
    }
}

export default NavLinks;
