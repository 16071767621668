import React, { Component } from 'react';
import {Button} from 'reactstrap';
import { FaArrowRight, FaArrowLeft } from 'react-icons/fa';

const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);


class MasterEventChooser extends Component {


    constructor (props) {
        super(props);

        let masterEventIndex=0;
        if (this.props.defaultIndex) {
            masterEventIndex = this.props.defaultIndex;
        }


        this.state = {
            MasterEventIndex: masterEventIndex,
            MasterEvent: {}
        };

        this.onNextEvent=this.onNextEvent.bind(this);
        this.onPreviousEvent=this.onPreviousEvent.bind(this);
        this.showNextButton = this.showNextButton.bind(this);
        this.showPreviousButton = this.showPreviousButton.bind(this);
    }

    async componentDidMount () {
        this.setState({
            MasterEvent: this.props.masterEvents[this.props.defaultIndex]
        });
    }

    async componentDidUpdate (prevProps) {
        if (this.props.defaultIndex!==prevProps.defaultIndex) {
            this.setState({
                MasterEvent: this.props.masterEvents[this.props.defaultIndex],
                MasterEventIndex: this.props.defaultIndex
            });
        }
    }


    onNextEvent (e) {
        let updatedIndex = this.state.MasterEventIndex+1;
        this.props.setMasterEvent(this.props.masterEvents[updatedIndex]);
        this.setState({
            MasterEventIndex: updatedIndex,
            MasterEvent: this.props.masterEvents[updatedIndex]
        });
    }

    onPreviousEvent (e) {
        let updatedIndex = this.state.MasterEventIndex-1;
        this.props.setMasterEvent(this.props.masterEvents[updatedIndex]);
        this.setState({
            MasterEventIndex: updatedIndex,
            MasterEvent: this.props.masterEvents[updatedIndex]
        });
    }

    showPreviousButton () {
        if (this.state.MasterEventIndex>0) {
            let dateString = '';
            if (this.props.masterEvents.length>0) {
                let dateValue = new Date(this.props.masterEvents[this.state.MasterEventIndex-1].EventTime);
                dateString = dateValue.toLocaleDateString("en-US");
            }

            return (<Button color="primary" onClick={this.onPreviousEvent} className="previousButton"><FaArrowLeft/> Previous
                [{dateString}]</Button>);
        }
        else {
            return (<div className="noPreviousButton"></div>);
        }
    }

    showNextButton () {
        winston.debug('Showing next button...');
        if (this.state.MasterEventIndex<this.props.masterEvents.length-1) {
            let dateString = '';
            if (this.props.masterEvents.length>0) {
                let dateValue = new Date(this.props.masterEvents[this.state.MasterEventIndex+1].EventTime);
                dateString = dateValue.toLocaleDateString("en-US");
            }

            return (<Button color="primary" onClick={this.onNextEvent} className="nextButton">Next
                [{dateString}]<FaArrowRight/></Button>);
        }
        else {
            return (<div className="noNextButton"></div>);
        }
    }

    render () {
        if (this.props.masterEvents.length>1) {
            return (
                <div>
                    <div className="buttonRow" >
                        {this.showPreviousButton()}
                        {this.showNextButton()}
                    </div>
                </div>
            );
        }
        else {
            return (<div></div>);
        }
    }
}

export default MasterEventChooser;