import React, {Component} from 'react';
import {Route, Switch, Redirect} from 'react-router-dom';
import Admin from './routes/Admin.js';
import Home from './routes/Home.js';
import About from './routes/About.js';
import Event from './routes/Event.js';
import NotFound from './routes/NotFound.js';
import MasterEvent from './routes/MasterEvent.js';
import Helmet from 'react-helmet';

import './App.css';
import 'bootstrap-css-only/css/bootstrap.min.css';

class App extends Component {
    render() {
        return (
            <div>
                <Helmet>
                    <meta name="keywords"
                          content="politics, party, event, watch party, state of the union, sotu, debate, republican, democrat, elections, election night, progressive, liberal, conservative, social"/>
                    <meta property="og:title" content="Find a Political Event Near You"/>
                    <meta property="og:site_name" content="PoliticalWatchParty.com"/>
                    <meta property="og:url" content="https://www.politicalwatchparty.com"/>
                    <meta property="og:description"
                          content="The best spot on the Internet for finding political watch parties. Search for events near you to watch state of the union speeches, debates, or election night events."/>
                    <meta property="og:type" content="website"/>
                    <meta property="og:image"
                          content="https://www.politicalwatchparty.com/PWPShare.png"/>
                    <title>PoliticalWatchParty.com</title>
                </Helmet>

                <Switch>
                    <Route exact path='/' component={Home}/>
                    <Route exact path='/admin' component={Admin}/>
                    <Route exact path='/about' component={About}/>
                    <Route path='/verify' component={Admin}/>
                    <Route path='/event/:eventName/:eventId' component={Event}/>
                    <Route path='/masterEvent/:masterEventId' component={MasterEvent}/>
                    <Route component={NotFound} status={404}/>
                </Switch>
            </div>
    );
    }
    }

    export default App;
