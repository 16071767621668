/*
 * Copyright 2018 Amazon.com, Inc. or its affiliates. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License"). You may not use this file except in compliance with
 * the License. A copy of the License is located at
 *
 *     http://aws.amazon.com/apache2.0/
 *
 * or in the "license" file accompanying this file. This file is distributed on an "AS IS" BASIS, WITHOUT WARRANTIES OR
 * CONDITIONS OF ANY KIND, either express or implied. See the License for the specific language governing permissions
 * and limitations under the License.
 */

export const Container = {};
export const FormContainer = {};
export const FormSection = {
    backgroundColor: '#1069ae',
    color: '#fff'
};
export const FormField = {};
export const SectionHeader = {
    backgroundColor: '#1069ae',
    color: '#fff'
};
export const SectionBody = {};
export const SectionFooter = {
    color: '#fff'
};
export const SectionFooterPrimaryContent = {};
export const SectionFooterSecondaryContent = {};
export const Input = {};
export const Button = {
    backgroundColor: '#fff',
    color: '#1069ae'
};
export const PhotoPickerButton = {};
export const PhotoPlaceholder = {};
export const SignInButton = {

};
export const SignInButtonIcon = {};
export const SignInButtonContent = {

};
export const Strike = {};
export const StrikeContent = {};
export const ActionRow = {};
export const FormRow = {};
export const A = {};
export const Hint = {
    color: '#fff'
};
export const Radio = {};
export const Label = {};
export const InputLabel = {
    color: '#fff'
};
export const AmazonSignInButton = {};
export const FacebookSignInButton = {};
export const GoogleSignInButton = {};
export const OAuthSignInButton = {};
export const Toast = {
    backgroundColor: "#f00",
    color: "#fff"
};
export const NavBar = {};
export const NavRight = {};
export const Nav = {};
export const NavItem = {};
export const NavButton = {};

const PWPTheme = {
    container: Container,
    formContainer: FormContainer,
    formSection: FormSection,
    formField: FormField,

    sectionHeader: SectionHeader,
    sectionBody: SectionBody,
    sectionFooter: SectionFooter,
    sectionFooterPrimaryContent: SectionFooterPrimaryContent,
    sectionFooterSecondaryContent: SectionFooterSecondaryContent,

    input: Input,
    button: Button,
    photoPickerButton: PhotoPickerButton,
    photoPlaceholder: PhotoPlaceholder,
    signInButton: SignInButton,
    signInButtonIcon: SignInButtonIcon,
    signInButtonContent: SignInButtonContent,
    amazonSignInButton: AmazonSignInButton,
    facebookSignInButton: FacebookSignInButton,
    googleSignInButton: GoogleSignInButton,
    oAuthSignInButton: OAuthSignInButton,

    formRow: FormRow,
    strike: Strike,
    strikeContent: StrikeContent,
    actionRow: ActionRow,
    a: A,

    hint: Hint,
    radio: Radio,
    label: Label,
    inputLabel: InputLabel,
    toast: Toast,

    navBar: NavBar,
    nav: Nav,
    navRight: NavRight,
    navItem: NavItem,
    navButton: NavButton
};

export default PWPTheme;
