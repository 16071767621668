//import googleGeocoder from 'google-geocoder';
import zipcodeLookup from 'zipcodes';
import {API} from 'aws-amplify';
import geohashpoly from 'geohash-poly';
import Geocode from "react-geocode";
import moment from "moment-timezone";

Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_KEY);

const winston = require('winston');
winston.level = process.env.REACT_APP_LOG_LEVEL;
const consoleTransport = new winston.transports.Console();
winston.add(consoleTransport);

class PWPGeoUtil {

    async getLatLong (address) {
        winston.debug('Geocode address: '+address);
        try {
            let geocodeResponse = await Geocode.fromAddress(address);
            if (geocodeResponse) {
                winston.debug('GEOCODE RESPONSE: '+JSON.stringify(geocodeResponse));
                winston.debug('GEOCODE Results: '+JSON.stringify(geocodeResponse.results));
            }
            if (!geocodeResponse || !geocodeResponse.results[0]) {
                return null;
            }
            else {
                winston.debug('LOCATION: ' + JSON.stringify(geocodeResponse.results[0]));
                return geocodeResponse.results[0].geometry.location;
            }
        }
        catch (err) {
            winston.error(err);
            throw err;
        }
    }

    //Uses a static list of zipcode information rather than a rate limited API call
    getLatLongFromZip (zipcode) {
        let location = zipcodeLookup.lookup(zipcode);
        if (location) {
            return {
                lat: location.latitude,
                lng: location.longitude
            }
        }
        else {
            return null;
        }
    }

    async getEventsForUser () {
        let apiPath = '/event/user';

        let apiCall = {
        };

        winston.debug('CALL: '+JSON.stringify(apiCall));

        let apiResponse = await API.get("EventAPI", apiPath, apiCall);
        return apiResponse;

    }

    async getClosestEvents (masterEventId, coordinates, zoomLevel, bounds) {

        winston.debug('BOUNDS: '+JSON.stringify(bounds));
        //If we have map bounds, it means map was moved, so search within it
        //otherwise search from center and surrounding area
        if (bounds && bounds.nw) {
            let poly = [[
                [bounds.nw.lng, bounds.nw.lat],
                [bounds.ne.lng, bounds.ne.lat],
                [bounds.sw.lng, bounds.sw.lat],
                [bounds.se.lng, bounds.se.lat],
                [bounds.nw.lng, bounds.nw.lat]
            ]];

            //Set precision
            let precision = 0;
            if (zoomLevel >=15) {
                precision = 5;
            }
            if (zoomLevel<15 && zoomLevel >= 13) {
                precision = 4;
            }
            if (zoomLevel<13 && zoomLevel >= 11) {
                precision = 3;
            }
            if (zoomLevel < 11 && zoomLevel > 7) {
                precision = 2;
            }
            if (zoomLevel <= 7 ) {
                precision = 1;
            }

            winston.debug('ZOOM/Precision: '+zoomLevel+'/'+precision);

            winston.debug('Getting hashes');
            let hashes = await this.getHashesFromPoly(poly, precision);


            let masterList = await this.loadResultsForMultipleHashes(masterEventId, hashes);
            winston.debug('MASTER LIST FROM POLY: '+masterList.size);

            return masterList;

        }
        else {
            return [];
        }
    }

    async getHashesFromPoly (poly, precision) {
        winston.debug('PRECISION: '+precision);
        return new Promise((resolve, reject) => {
            geohashpoly(
                {coords: poly, precision: precision, hashMode: 'extent'},
                (err, hashes) => {
                    if (err) {
                        winston.error('Error getting hashes: '+err);
                        reject();
                    }
                    else {
                        winston.debug('HASHES: ' + hashes);
                        resolve(hashes);
                    }
                });
        });

    }

    async loadSingleEvent (eventId) {

        let apiPath = '/event/object/'+eventId;

        let apiCall = {
        };

        const apiResponse = await API.get("EventAPI", apiPath, apiCall);

        winston.debug('Single Event Load: '+JSON.stringify(apiResponse));

        return apiResponse;
    }

    async loadSingleMasterEvent (masterEventId) {

        let apiPath = '/masterEvent/object/'+masterEventId;

        let apiCall = {
        };

        const apiResponse = await API.get("MasterEvents", apiPath, apiCall);

        return apiResponse;
    }

    /**
     *
     * @param masterEventId
     * @param neighbors
     * @return {Promise<Array>}
     */
    async loadResultsForMultipleHashes (masterEventId, hashes) {
        let allResultList = [];

        let hashList = [];

        for (let index in hashes) {
            hashList.push(hashes[index]);
        }

        await Promise.all(hashList.map(async (hash) => {
            let results = await this.loadResultsForSingleHash(masterEventId, hash);

            winston.debug('Result Count: '+results.size);
            allResultList = allResultList.concat(results);
        }));

        return allResultList;

    }


    async loadResultsForSingleHash (masterEventId, geohash) {
        let apiPath = '/event';

        let apiCall = {
            'queryStringParameters':{
                'MasterEventId':masterEventId
            }
        };

        if (geohash) {
            apiCall.queryStringParameters.Geohash=geohash;
        }

        winston.debug('CALL: '+JSON.stringify(apiCall));

        const apiResponse = await API.get("EventAPI", apiPath, apiCall);
        winston.debug('Events Returned: '+JSON.stringify(apiResponse));
        return apiResponse;
    }

    getMasterEventDateString (eventTime) {

        if (eventTime) {
            let eventMoment = moment(eventTime);
            let zone = moment.tz.guess();
            let zoneAbbr = moment.tz(zone).format("z");

            let dateString= eventMoment.format("MMMM Do, YYYY [@] h:mm a")+" "+zoneAbbr;


            return dateString;
        }
        else {
            return '';
        }
    }


}

export default PWPGeoUtil;

