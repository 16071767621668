// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_dynamodb_all_tables_region": "us-east-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "Event-prod",
            "region": "us-east-1"
        },
        {
            "tableName": "MasterEvents-prod",
            "region": "us-east-1"
        }
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "EventAPI",
            "endpoint": "https://1tlg5nrkxi.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        },
        {
            "name": "MasterEvents",
            "endpoint": "https://3gvdjpntsf.execute-api.us-east-1.amazonaws.com/prod",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "politicalwatchparty-prod",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d9h8lcp2k0rmo.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:5a92c783-edc4-4200-888c-88db7ccf1490",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_BmHszSwnu",
    "aws_user_pools_web_client_id": "5dcaip0p8lrhujoqb9t94upuvd",
    "oauth": {}
};


export default awsmobile;
